import React from "react"

function BeachTopLeft({ children, ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 32">
      <title>beach-top-left</title>
      <path d="M52.707 0h0.699l-0.465 0.234z"></path>
      <path d="M48.165 0h2.275l1.048 1.048c-0.482 0.287-0.947 0.577-1.398 0.877z"></path>
      <path d="M43.643 0h2.268l2.865 2.865q-0.65 0.507-1.244 1.024z"></path>
      <path d="M39.115 0h2.264l4.983 4.983q-0.577 0.577-1.101 1.164z"></path>
      <path d="M34.583 0h2.264l7.376 7.373c-0.349 0.426-0.667 0.853-0.985 1.282z"></path>
      <path d="M30.054 0h2.264l9.983 9.986c-0.311 0.451-0.611 0.905-0.912 1.356z"></path>
      <path d="M25.522 0h2.268l12.688 12.705c-0.3 0.458-0.601 0.909-0.908 1.356z"></path>
      <path d="M20.994 0h2.264l15.392 15.375c-0.321 0.433-0.65 0.86-0.992 1.272z"></path>
      <path d="M16.462 0h2.264l17.866 17.862c-0.367 0.384-0.751 0.751-1.16 1.104z"></path>
      <path d="M11.93 0h2.268l19.966 19.966c-0.394 0.283-0.85 0.575-1.322 0.841l-0.075 0.039z"></path>
      <path d="M7.401 0h2.264l21.608 21.605c-0.349 0.147-0.678 0.287-1.048 0.419-0.203 0.077-0.405 0.147-0.605 0.21z"></path>
      <path d="M2.873 0h2.264l22.684 22.681c-0.587 0.105-1.262 0.165-1.952 0.165-0.051 0-0.101-0-0.152-0.001l0.008 0z"></path>
      <path d="M0.008 1.667v-1.667h0.601l22.531 22.534c-1.308-0.316-2.43-0.693-3.507-1.155l0.142 0.054z"></path>
      <path d="M0.008 6.199v-2.268l15.962 15.962c-0.864-0.327-1.87-0.573-2.914-0.693l-0.059-0.006z"></path>
      <path d="M0.008 6.195v-2.268l15.965 15.965c-0.864-0.327-1.87-0.573-2.914-0.693l-0.059-0.006z"></path>
      <path d="M0.008 10.724v-2.264l10.769 10.776c-0.129 0.017-0.255 0.042-0.384 0.070-0.566 0.114-1.045 0.245-1.511 0.405l0.082-0.024z"></path>
      <path d="M0.008 15.252v-2.264l7.38 7.38c-0.523 0.278-0.973 0.574-1.392 0.906l0.019-0.015z"></path>
      <path d="M0.008 19.77v-2.264l4.829 4.836c-0.351 0.382-0.684 0.797-0.988 1.233l-0.026 0.039z"></path>
      <path d="M0.008 24.313v-2.268l2.96 2.967c-0.269 0.503-0.514 1.017-0.737 1.527z"></path>
      <path d="M0.008 28.855v-2.268l1.569 1.569c-0.217 0.559-0.419 1.111-0.622 1.642z"></path>
      <path d="M0.008 32v-0.891l0.287 0.29c-0.091 0.206-0.189 0.409-0.287 0.601z"></path>
    </svg>
  )
}

export default BeachTopLeft
