import React, { useState, useEffect, useRef, cloneElement } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import particleSystem from "./particleSystem"

const StyledParticleSystem = styled.div`
  position: relative;
`

const Particle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  /* > * {
    transition: transform 1s ease-in-out;
  } */
`

function ParticleSystem({
  id,
  model,
  speed,
  power,
  rotate,
  children,
  ...props
}) {
  const system = particleSystem(power)
  const [particles, setParticles] = useState(system.init(model))

  const RAF = useRef()

  const updateSystem = (time) => {
    setParticles(system.update(particles, (time / 10) * speed))
    RAF.current = requestAnimationFrame(updateSystem)
  }

  useEffect(() => {
    RAF.current = requestAnimationFrame(updateSystem)
    return () => cancelAnimationFrame(updateSystem)
  }, [])

  const Rotated = ({ rotation, children }) => {
    return (
      <div
        style={{
          transform: `rotate(${rotation}deg)`,
          transformOrigin: "-87px 200px",
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <StyledParticleSystem {...props}>
      {particles.map((particle, i) => {
        const clonedChildren = cloneElement(children, {
          style: {
            transform: `scale(${particle.size / 100})`,
          },
        })
        const key = `particle_system_${id}_particle_${i}`
        if (rotate) {
          return (
            <Rotated rotation={particle.rotation}>
              <Particle
                key={key}
                style={{
                  transform: `translate(${particle.position[0]}px, ${-particle
                    .position[1]}px)`,
                }}
              >
                {clonedChildren}
              </Particle>
            </Rotated>
          )
        }
        return (
          <Particle
            key={key}
            style={{
              transform: `translate(${particle.position[0]}px, ${-particle
                .position[1]}px)`,
            }}
          >
            {clonedChildren}
          </Particle>
        )
      })}
    </StyledParticleSystem>
  )
}

export default ParticleSystem

ParticleSystem.defaultProps = {
  speed: 1,
  model: [{ position: [0, 0], size: 100 }],
  rotate: false,
}

ParticleSystem.propTypes = {
  speed: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rotate: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
