import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import RenderShape from "../RenderShape"

const StyledCorner = styled.div`
  position: absolute;
  width: ${(props) => props.size};
  ${(props) => props.top && "top: 0"};
  ${(props) => props.left && "left: 0"};
  ${(props) => props.right && "right: 0"};
  ${(props) => props.bottom && "bottom: 0"};
  ${(props) =>
    props.position &&
    css`
      ${(props) => props.position.top && `top: ${props.position.top}`};
      ${(props) => props.position.left && `left: ${props.position.left}`};
      ${(props) => props.position.right && `right: ${props.position.right}`};
      ${(props) => props.position.bottom && `bottom: ${props.position.bottom}`};
    `}
`

function InCorner({
  children,
  shape,
  size,
  top,
  left,
  right,
  bottom,
  position,
  ...props
}) {
  return (
    <StyledCorner {...{ size, position, top, left, right, bottom }} {...props}>
      <RenderShape shape={shape} />
    </StyledCorner>
  )
}

export default InCorner

InCorner.defaultProps = {
  size: "50%",
}
InCorner.propTypes = {}
