import React from "react"

function BeachTopRight({ ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 32">
      <title>beach-top-right</title>
      <path d="M0.699 0h-0.699l0.465 0.234z"></path>
      <path d="M5.241 0h-2.275l-1.048 1.048c0.482 0.287 0.947 0.577 1.398 0.877z"></path>
      <path d="M9.763 0h-2.268l-2.865 2.865q0.65 0.507 1.244 1.024z"></path>
      <path d="M14.291 0h-2.264l-4.983 4.983q0.577 0.577 1.101 1.164z"></path>
      <path d="M18.823 0h-2.264l-7.376 7.373c0.349 0.426 0.667 0.853 0.985 1.282z"></path>
      <path d="M23.352 0h-2.264l-9.983 9.986c0.311 0.451 0.611 0.905 0.912 1.356z"></path>
      <path d="M27.884 0h-2.268l-12.688 12.705c0.3 0.458 0.601 0.909 0.909 1.356z"></path>
      <path d="M32.412 0h-2.264l-15.392 15.375c0.321 0.433 0.65 0.86 0.992 1.272z"></path>
      <path d="M36.944 0h-2.264l-17.866 17.862c0.367 0.384 0.751 0.751 1.16 1.104z"></path>
      <path d="M41.476 0h-2.268l-19.966 19.966c0.393 0.283 0.85 0.575 1.322 0.841l0.075 0.039z"></path>
      <path d="M46.005 0h-2.264l-21.608 21.605c0.349 0.147 0.678 0.287 1.048 0.419 0.203 0.077 0.405 0.147 0.605 0.21z"></path>
      <path d="M50.533 0h-2.264l-22.684 22.681c0.587 0.105 1.262 0.165 1.952 0.165 0.051 0 0.101-0 0.152-0.001l-0.008 0z"></path>
      <path d="M53.399 1.667v-1.667h-0.601l-22.531 22.534c1.308-0.316 2.43-0.693 3.507-1.155l-0.142 0.054z"></path>
      <path d="M53.399 6.199v-2.268l-15.962 15.962c0.864-0.327 1.87-0.573 2.914-0.693l0.059-0.006z"></path>
      <path d="M53.399 6.195v-2.268l-15.965 15.965c0.864-0.327 1.87-0.573 2.914-0.693l0.059-0.006z"></path>
      <path d="M53.399 10.724v-2.264l-10.769 10.776c0.129 0.017 0.255 0.042 0.384 0.070 0.566 0.114 1.045 0.245 1.511 0.405l-0.082-0.024z"></path>
      <path d="M53.399 15.252v-2.264l-7.38 7.38c0.523 0.278 0.973 0.574 1.392 0.906l-0.019-0.015z"></path>
      <path d="M53.399 19.77v-2.264l-4.829 4.836c0.351 0.382 0.684 0.797 0.988 1.233l0.026 0.039z"></path>
      <path d="M53.399 24.313v-2.268l-2.96 2.967c0.269 0.503 0.514 1.017 0.737 1.527z"></path>
      <path d="M53.399 28.855v-2.268l-1.569 1.569c0.217 0.559 0.419 1.111 0.622 1.642z"></path>
      <path d="M53.399 32v-0.891l-0.287 0.29c0.091 0.206 0.189 0.409 0.287 0.601z"></path>
    </svg>
  )
}

export default BeachTopRight
