export default (power = 1) => {
  let model = null

  const init = (particles) => {
    model = particles.map((particle, i) => ({ ...particle, id: i }))
    return model.map((particle) => ({ ...particle, birth: 0 }))
  }

  const nrt = (x, n) => Math.pow(x, 1 / n)

  const rise = (particle, t, speed) =>
    particle.position[1] + (t / Math.pow(particle.size, 1.8)) * speed

  const float = (particle, t, f, a) =>
    particle.position[1] +
    (Math.sin((t * f) / nrt(particle.size, 4) / 1000) * (a * power)) /
      nrt(particle.size, 5)

  const oscilate = (particle, t, f, a) =>
    particle.position[0] +
    Math.sin(((t / nrt(particle.size, 4)) * f) / 10) *
      ((a * nrt(power, 2)) / nrt(particle.size, 15))

  const spin = (particle, t, f, a) =>
    particle.rotation + Math.sin((t / 1000) * f) * (a / 10)

  const transform = (particle, obj) => ({ ...particle, ...obj })

  const update = (particles, time) => {
    return particles.map((particle) => {
      const t = time - particle.birth

      return transform(particle, {
        position: [oscilate(particle, t, 0.5, 8), float(particle, t, 10, 87)],
        rotation: spin(particle, t, 13, 25),
      })
    })
  }

  return {
    init,
    update,
  }
}
