import React from "react"

function CoralWaveTopHorizontalLeft({ children, ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>coral-wave-top-horizontal-left</title>
      <path d="M0 0v24.096c1.902 0.015 3.716-0.481 5.109-1.589 3.703-2.956 1.581-6.69 5.349-10.274s8.049-0.388 14.811-3.545c1.689-0.811 3.104-1.946 4.214-3.335l0.018-0.024c1.209-1.48 2.085-3.278 2.485-5.25l0.013-0.078z"></path>
    </svg>
  )
}

export default CoralWaveTopHorizontalLeft
