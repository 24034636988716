import React from "react"
import PropTypes from "prop-types"
import WaveBottomLeft from "./shapes/WaveBottomLeft"
import WaveBottomRight from "./shapes/WaveBottomRight"
import WaveTopRight from "./shapes/WaveTopRight"
import CoralWaveBottomRight from "./shapes/CoralWaveBottomRight"
import CoralWaveBottomLeft from "./shapes/CoralWaveBottomLeft"
import CoralWaveTopRight from "./shapes/CoralWaveTopRight"
import CoralWaveTopHorizontalLeft from "./shapes/CoralWaveTopHorizontalLeft"
import BeachTopLeft from "./shapes/BeachTopLeft"
import BeachTopRight from "./shapes/BeachTopRight"
import BeachBottomLeft from "./shapes/BeachBottomLeft"
import Coral from "./shapes/Coral"

function RenderShape({ shape, flip, ...props }) {
  switch (shape) {
    case "wave-bottom-left":
      return flip ? (
        <WaveBottomRight {...props} />
      ) : (
        <WaveBottomLeft {...props} />
      )
    case "wave-bottom-right":
      return flip ? (
        <WaveBottomLeft {...props} />
      ) : (
        <WaveBottomRight {...props} />
      )
    case "wave-top-right":
      return <WaveTopRight {...props} />
    case "coral-wave-bottom-right":
      return <CoralWaveBottomRight {...props} />
    case "coral-wave-bottom-left":
      return <CoralWaveBottomLeft {...props} />
    case "coral-wave-top-right":
      return <CoralWaveTopRight {...props} />
    case "coral-wave-top-horizontal-left":
      return <CoralWaveTopHorizontalLeft {...props} />
    case "beach-top-left":
      return <BeachTopLeft {...props} />
    case "beach-top-right":
      return <BeachTopRight {...props} />
    case "beach-bottom-left":
      return <BeachBottomLeft {...props} />
    case "coral":
      return <Coral {...props} />
    default:
      console.warn("No such shape", shape)
  }
  return null
}

export default RenderShape

RenderShape.defaultProps = {}
RenderShape.propTypes = {
  shape: PropTypes.string,
}
