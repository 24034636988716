import React, { Component, useContext } from "react"
import PropTypes from "prop-types"
import { ViewportContext } from "context"

function Responsive({
  phone,
  tabletPortrait,
  tabletLandscape,
  desktop,
  ...rest
}) {
  const viewport = useContext(ViewportContext)

  function renderComponent(vp) {
    if (vp === "PHONE_ONLY") {
      if (phone === false) return null
      if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === "TABLET_PORTRAIT_UP") {
      if (tabletPortrait === false) return null
      if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === "TABLET_LANDSCAPE_UP") {
      if (tabletLandscape === false) return null
      if (tabletLandscape) {
        return <tabletLandscape.type {...tabletLandscape.props} {...rest} />
      } else if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    } else if (vp === "DESKTOP") {
      if (desktop === false) return null
      if (desktop) {
        return <desktop.type {...desktop.props} {...rest} />
      } else if (tabletLandscape) {
        return <tabletLandscape.type {...tabletLandscape.props} {...rest} />
      } else if (tabletPortrait) {
        return <tabletPortrait.type {...tabletPortrait.props} {...rest} />
      } else if (phone) {
        return <phone.type {...phone.props} {...rest} />
      } else return null
    }

    return null
  }

  return renderComponent(viewport)
}

export default Responsive

Responsive.defaultProps = {
  phone: null,
  tabletPortrait: null,
  tabletLandscape: null,
  desktop: null,
}

Responsive.propTypes = {
  phone: PropTypes.node,
  tabletPortrait: PropTypes.node,
  tabletLandscape: PropTypes.node,
  desktop: PropTypes.node,
}
