import React from "react"
import PropTypes from "prop-types"

function WaveBottomRight({ ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 32"
      {...props}
    >
      <path d="M49.594 0c-2.954 1.167-4.666 3.902-6.010 6.812-0.365 0.782-0.7 1.578-1.024 2.356-2.849 6.792-8.317 12.872-16.022 13.593-4.873 0.458-9.705-0.784-14.581 0.035-4.794 0.808-9.871 4.476-11.957 9.204h49.594z"></path>
    </svg>
  )
}

export default WaveBottomRight
