import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useScrollPosition } from "hooks/useScrollPosition"

const StyledBase = styled.div`
  fill: ${(props) => props.theme.colors[props.color]};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  ${(props) =>
    props.depth &&
    css`
      position: fixed;
      z-index: ${props.depth * -1};
    `}
`

function Base({ children, color, parallax, ...props }) {
  const scrollPosition = useScrollPosition()

  const getParallaxLevel = (level) => {
    switch (level) {
      case "Level 1":
        return 1
      case "Level 2":
        return 2
      default:
        return false
    }
  }
  const depth = useMemo(() => getParallaxLevel(parallax))
  const scaledDepth = useMemo(() => Math.pow(depth, -1 / 4))

  return (
    <StyledBase
      color={color}
      depth={getParallaxLevel(parallax)}
      {...props}
      style={{
        transform: `translateY(-${(scrollPosition * scaledDepth) / 1.5}px)`,
      }}
    >
      {children}
    </StyledBase>
  )
}

export default Base

Base.defaultProps = {}
Base.propTypes = {
  children: PropTypes.node.isRequired,
}
