import React from "react"
import PropTypes from "prop-types"

function CoralWaveTopRight({ children, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 32"
      {...props}
    >
      <path d="M24.094 0h-24.096c-0.015 1.902 0.481 3.716 1.589 5.109 2.956 3.703 6.69 1.581 10.274 5.349s0.388 8.049 3.545 14.811c0.811 1.689 1.946 3.104 3.335 4.214l0.024 0.018c1.48 1.209 3.278 2.085 5.25 2.485l0.078 0.013z"></path>
    </svg>
  )
}

export default CoralWaveTopRight

CoralWaveTopRight.defaultProps = {
  children: null,
}
CoralWaveTopRight.propTypes = {
  children: PropTypes.node,
}
