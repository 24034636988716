import React, { cloneElement } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Responsive from "components/Responsive"
import Base from "./composition/Base"
import InCorner from "./composition/InCorner"
import ParticleSystem from "./ParticleSystem"
import RenderShape from "./RenderShape"

import { getIconFromUrl } from "utils/iconResolver"

const Floating = styled.div`
  position: relative;
  padding: 0 10rem;
  &.Left {
    float: left;
  }
  &.Right {
    float: right;
  }
  ${(props) =>
    props.position &&
    css`
      ${(props) => props.position.top && `top: ${props.position.top}`};
      ${(props) => props.position.left && `left: ${props.position.left}`};
      ${(props) => props.position.right && `right: ${props.position.right}`};
      ${(props) => props.position.bottom && `bottom: ${props.position.bottom}`};
    `}
`

function BrandElement({ graphic, color, parallax, ...props }) {
  const shape = getIconFromUrl(graphic.url)

  const groups = {
    floating: {
      bubble: {
        name: "bubble",
        position: { top: "-4.75rem" },
        direction: props.direction,
        id: props.id,
        index: 1,
      },
      coral: {
        name: "logo-icon",
        position: { top: "-15rem" },
        direction: props.direction,
        id: props.id,
        index: 2,
      },
    },
    cornered: {
      bottomLeft: [
        {
          name: "beach-bottom-left",
          size: "35%",
        },
      ],
      topLeft: [
        {
          name: "coral-wave-top-horizontal-left",
          size: "42rem",
        },
        {
          name: "beach-top-left",
          size: "45rem",
          position: { top: "-2.5rem", left: "8rem" },
        },
      ],
      topRight: [
        {
          name: "wave-top-right",
          size: "80rem",
        },
        {
          name: "beach-top-right",
          size: "53rem",
          position: { top: "8rem", right: "7.5rem" },
        },
      ],
    },
  }

  const RenderTabletUp = ({ children }) => (
    <Responsive tabletLandscape={children} />
  )

  const makeKey = (item, index) => `${item.name}_${index}`

  const RenderCornerGroup = ({ group, children }) => {
    return (
      <>
        {group.map((item, i) => {
          if (item.name !== shape) return null
          const key = makeKey(item, i)
          const { size } = item
          const cornerProps = { size, position: item?.position }
          const clonedChildren = cloneElement(children, {
            ...cornerProps,
            shape: item.name,
          })
          return <RenderTabletUp key={key}>{clonedChildren}</RenderTabletUp>
        })}
      </>
    )
  }

  const RenderFloating = ({ type, children }) => {
    if (type.name !== shape) return null
    const key = makeKey(type, type.index)
    const clonedChildren = cloneElement(children, {
      id: type.id,
    })
    return (
      <RenderTabletUp key={key}>
        <Floating position={type.position} className={type.direction}>
          {clonedChildren}
        </Floating>
      </RenderTabletUp>
    )
  }

  return (
    <Base color={color} parallax={parallax} {...props}>
      <RenderCornerGroup group={groups.cornered.topLeft}>
        <InCorner top left />
      </RenderCornerGroup>
      <RenderCornerGroup group={groups.cornered.topRight}>
        <InCorner top right />
      </RenderCornerGroup>
      <RenderCornerGroup group={groups.cornered.bottomLeft}>
        <InCorner bottom left />
      </RenderCornerGroup>

      <RenderFloating type={groups.floating.bubble}>
        <ParticleSystem model={props.particles}>
          <svg height="100" width="100">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </ParticleSystem>
      </RenderFloating>

      <RenderFloating type={groups.floating.coral}>
        <>
          <ParticleSystem
            power={0.95}
            model={[{ position: [-270, 0], size: 150, rotation: -2 }]}
            rotate
          >
            <RenderShape shape="coral" />
          </ParticleSystem>
          <ParticleSystem
            speed={1.2}
            power={0.6}
            model={[
              { position: [-350, 30], size: 60 },
              { position: [-130, 130], size: 30 },
            ]}
          >
            <svg height="100" width="100">
              <circle cx="50" cy="50" r="50" />
            </svg>
          </ParticleSystem>
        </>
      </RenderFloating>
    </Base>
  )
}

export default BrandElement

BrandElement.defaultProps = {}
BrandElement.propTypes = {
  shape: PropTypes.string,
}
