import React from "react"
import BrandElement from "./index"
import { graphql } from "gatsby"

function BrandElementGroups({ items, ...props }) {
  const groups = items.map((item) => {
    const { brand_element_group_item: i } = item
    return { group: i.brand_group, id: i._meta.id }
  })
  return groups.map((i) => {
    return i.group.map((j, index) => {
      return <BrandElement {...j} key={`${i.id}_${index}`}></BrandElement>
    })
  })
}

export default BrandElementGroups

export const query = graphql`
  fragment BrandElementsGroup on PRISMIC_Brand_element_group {
    brand_group {
      graphic
      color
      parallax
    }
    _meta {
      id
    }
  }
`
